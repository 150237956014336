import React from "react";
import SearchResultMap from "./SearchResultMap";
import { useTranslation } from "react-i18next";

const SearchResult = ({ resultsTitle, loading, setIsOpen, setInp }) => {
  const { t } = useTranslation();

  return (
    <div className="search_list_modal">
      {loading ? (
        <div className="search_loader">
          <span className="loader_search"></span>
        </div>
      ) : resultsTitle.length > 0 ? (
        resultsTitle.map((data, i) => {
          return <SearchResultMap data={data} key={i} setIsOpen={setIsOpen} setInp={setInp} />;
        })
      ) : (
        <p className="if_no_result_search">{t("searchErr")}</p>
      )}
    </div>
  );
};

export default SearchResult;
