import React from "react";
import Navbar from "../components/Navbar";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import {HelmetProvider } from "react-helmet-async";

import titles from "./titles/titles";

import img from "../images/leadtech_logo.png";

const ContactPage = () => {
  return (
    <>
      <HelmetProvider>
        <meta
          name="keywords"
          content="LEADTECH.AZ Əlaqə. Məişət texnikası alışı üçün əlaqə nömrələri. LEADTECH.AZ əlaqə nömrəsi (+994)10 260 39 02"
        />
        <meta
          name="description"
          content="LEADTECH.AZ Əlaqə. LEADTECH.AZ əlaqə nömrəsi (+994)10 260 39 02"
        />
        <title>{titles.contact}</title> 
        <meta property="og:locale" content="az_AZ" />
        <meta property="og:title" content="LEADTECH.AZ Əlaqə" />
        <meta property="og:description" content="LEADTECH.AZ Əlaqə" />
        <meta property="og:image" content={img} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:url" content="https://leadtech.az/contact" />
        <meta property="og:type" content="article" />
      </HelmetProvider>
      <Navbar />
      <Contact />
      <Footer />
    </>
  );
};

export default ContactPage;
