import React from "react";
import i18n from "../18n";

const InstCards = ({ data }) => {
  const currentLanguage = i18n.language;

  return (
    <div className="priceinfo_cartslider">
      <div className="cart_slider_logo">
        <img src={data?.image} width="80" height="24" alt="Inst Cards" />
      </div>
      <div className="cart_slider_info">
        <h4>
          {data?.translations?.[currentLanguage]?.bottomtxt ||
            data?.translations?.az?.bottomtxt}
        </h4>
        <p>
          {data?.translations?.[currentLanguage]?.headtxt ||
            data?.translations?.az?.headtxt}
        </p>
      </div>
    </div>
  );
};

export default InstCards;
