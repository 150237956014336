import React from "react";
import i18n from "../18n";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SearchResultMap = ({ data, setIsOpen, setInp }) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  const productNameForURL = data?.translations?.az?.name
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/ş/g, "sh")
    .replace(/ə/g, "e")
    .replace(/ü/g, "u")
    .replace(/ı/g, "i")
    .replace(/ğ/g, "g")
    .replace(/ö/g, "o")
    .replace(/ç/g, "ch");
    
  const handleLinkClick = () => {
    setIsOpen(false);
    setInp("");
  };

  return (
    <>
      <Link
        onClick={handleLinkClick}
        to={`/product/${encodeURIComponent(productNameForURL)}/${data?.id}`}
      >
        <>
          <ul>
            <li className="search_list_style">
              {data?.translations[currentLanguage]?.name ||
                data?.translations?.az?.name}
              , {t("categorSearch")}:{" "}
              <span className="search_result_span">
                {data?.category?.translations[currentLanguage].name ||
                  data?.category?.translations?.az?.name}
              </span>
            </li>
          </ul>
        </>
      </Link>
    </>
  );
};

export default SearchResultMap;
