import React, { useEffect, useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import ProductSelectedCategory from "./ProductSelectedCategory";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryAtributes,
  getFilteredProducts,
} from "../action/MainAction";
import { useTranslation } from "react-i18next";
import ProdProducer from "./ProdProducer";
import { LiaTimesSolid } from "react-icons/lia";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import img from "../images/leadtech_logo.png";

import { isLoaderFuncStart, resetCatAtri } from "../redux/MainReducer";
import Loading from "./Loading";
import { Helmet } from "react-helmet-async";

const MIN = 0;
const MAX = 0;

const SelectedCategory = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Show filter state accordion
  const [isShowFilter, setIsShowFilter] = useState(false);
  // React slider state and change
  const [values, setValues] = useState([MIN, MAX]);
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");

  // Selected chekbox value
  const [selectedItems, setSelectedItems] = useState([]);

  // ID of category in URL
  const catID = new URLSearchParams(window.location.search).get("id");

  const currLang = i18n.language;

  useEffect(() => {
    // Call Category Attributes by ID
    dispatch(getCategoryAtributes(catID));

    // Call product list with pagination by 12 pieces
    dispatch(
      getFilteredProducts(`https://api.leadtech.az/api/all-products/${catID}/`)
    );
  }, [dispatch, catID]);

  const filterResults = useSelector((state) => state.Data.filterResults);
  const categoryAtributes = useSelector(
    (state) => state.Data.categoryAtributes
  );
  const loaderTwo = useSelector((state) => state.Data.loaderTwo);

  const categoryName = useSelector((state) => state.Data.categoryName);

  useEffect(() => {
    if (filterResults?.min_price && filterResults?.max_price) {
      // Устанавливаем начальные значения цен на основе данных с сервера
      setValues([filterResults.min_price, filterResults.max_price]);
    }
  }, [filterResults]);

  // Обработчики для инпутов
  const handleMinPriceChange = (e) => {
    setMinValue(e.target.value);
  };

  const handleMaxPriceChange = (e) => {
    setMaxValue(e.target.value);
  };

  // Сбросыввание значений фильтра
  const resetAllValue = () => {
    // Обнуляем выбранные элементы
    setSelectedItems([]);

    dispatch(isLoaderFuncStart()); // Loader start
    dispatch(resetCatAtri()); // Reset categoryAtributes
    window.scrollTo({ top: 0, behavior: "smooth" });
    setMinValue("");
    setMaxValue("");
    setResetBtnstate(false);
    dispatch(getCategoryAtributes(catID));

    dispatch(
      getFilteredProducts(`https://api.leadtech.az/api/all-products/${catID}/`)
    );
  };

  // Поиск цены на основе выбранного диапазона
  const searchPriceBtn = () => {
    if (!minValue || !maxValue) {
      console.log("Недостаточно данных для фильтрации.");

      return; // Прерываем выполнение функции, если нет minValue или maxValue
    }

    dispatch(isLoaderFuncStart());
    // Появление кнопки сбросить
    setResetBtnstate(true);
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Проверяем, если чекбоксы выбраны

    if (selectedItems.length > 0) {
      // Если выбраны чекбоксы, отправляем запрос с ценой и аттрибутами
      sendRequestToServer();
    } else {
      // Если чекбоксы не выбраны, отправляем запрос только с ценой
      dispatch(
        getFilteredProducts(
          `https://api.leadtech.az/api/all-products/${catID}/?min_price=${minValue}&max_price=${maxValue}`
        )
      );
    }
  };

  // Поиск товаров по выбранным атрибутам и их значениям
  const handleCheckboxChange = (item) => {
    // Появление кнопки сбросить
    setResetBtnstate(true);

    window.scrollTo({ top: 0, behavior: "smooth" });

    const attribute = item.attribute;
    const isSelected = selectedItems?.some(
      (selectedItem) =>
        selectedItem.attribute === attribute && selectedItem.id === item.id
    );

    if (!isSelected) {
      setSelectedItems([...selectedItems, { id: item.id, attribute }]);
    } else {
      // Если выбранных значений нет, то кнопка сбросить изчезает
      setResetBtnstate(false);

      const updatedItems = selectedItems.filter(
        (selectedItem) =>
          !(selectedItem.attribute === attribute && selectedItem.id === item.id)
      );
      setSelectedItems(updatedItems);
    }
  };

  // Отправка данных на основе изменений chekbox and price slider
  const sendRequestToServer = () => {
    dispatch(isLoaderFuncStart());

    let queryParams = [];

    // Группируем выбранные чекбоксы по атрибутам
    const groupedItems = selectedItems.reduce((grouped, item) => {
      if (!grouped[item.attribute]) {
        grouped[item.attribute] = [];
      }
      grouped[item.attribute].push(item.id);
      return grouped;
    }, {});

    // Добавляем фильтры по чекбоксам, если они есть
    if (Object.keys(groupedItems).length > 0) {
      queryParams = Object.entries(groupedItems).map(
        ([attribute, values]) =>
          `attribute=${attribute}&value=${values.join(",")}`
      );
    }

    // Добавляем фильтры по диапазону цен, если они есть
    if (minValue && maxValue) {
      queryParams.push(`min_price=${minValue}&max_price=${maxValue}`);
    }

    // Формируем URL с параметрами фильтрации
    let apiUrl = `https://api.leadtech.az/api/all-products/${catID}/`;
    if (queryParams.length > 0) {
      apiUrl += `?${queryParams.join("&")}`;
    }

    // Выводим сформированный URL в консоль
    // console.log("API URL:", apiUrl);

    // Отправляем запрос
    dispatch(getFilteredProducts(apiUrl));

    setCurrentPage(1); // сброс страницы на 1 после фильтрации
  };
  useEffect(() => {
    sendRequestToServer();
  }, [selectedItems, catID, dispatch]);

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filterResults?.count / 12);

  // Смена показа продуктов при нажатии на числа пагинации
  const handlePageClick = async (pageNumb) => {
    dispatch(isLoaderFuncStart());

    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(pageNumb); // Устанавливаем текущую страницу

    if (filterResults.next) {
      const updatedUrl = filterResults.next.replace(
        /(page=)\d+/,
        `$1${pageNumb}`
      );
      dispatch(getFilteredProducts(updatedUrl));
    } else {
      dispatch(getFilteredProducts(filterResults.previous));
    }
  };

  const [isActive, setIsActive] = useState(false);
  const accorOpen = () => {
    setIsActive(!isActive);
  };

  // true для "по возрастанию", false для "по убыванию"
  const [sortOrder, setSortOrder] = useState(null);

  // state для хранения сортированного списка товаров
  const [sortedArr, setSortedArr] = useState([]);

  // Если btnTrue = true, то в компоненте (на странице) ProductSelectedCategory
  // будет отображатся данные sortedArr, которые уже сортированные
  const [btnTrue, setBtntrue] = useState(false);

  // State для обнуления поиска
  const [resetBtnstate, setResetBtnstate] = useState(false);

  // Функция сортировки
  const sortProducts = (results) => {
    const sortedResults = [...results].sort((a, b) => {
      if (sortOrder) {
        return parseFloat(a?.price) - parseFloat(b?.price); // по возрастанию
      } else {
        return parseFloat(b?.price) - parseFloat(a?.price); // по убыванию
      }
    });
    setSortedArr(sortedResults);
  };

  // Функция для сортировки товаров по возрастанию цен
  const sortAscending = () => {
    setSortOrder(true); // меняем порядок на возрастание
    sortProducts(filterResults?.results);
    setBtntrue(true);
  };

  // // Функция для сортировки товаров по убыванию цен
  const sortDescending = () => {
    setSortOrder(false); // меняем порядок на убывание
    sortProducts(filterResults?.results);
    setBtntrue(true);
  };

  useEffect(() => {
    if (Array.isArray(filterResults?.results)) {
      sortProducts(filterResults?.results);
    }
  }, [filterResults?.results, sortOrder]);

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content={`${categoryName?.[currLang]} Bakıda almaq - LEADTECH.AZ`}
        />
        <meta
          name="description"
          content={`${categoryName?.[currLang]} LEADTECH.AZ mağazasında satışı. Bakıda almaq - LEADTECH.AZ əlaqə nömrəsi (+994)10 260 39 02`}
        />
        {/* <title>{`${categoryName?.[currLang]} Bakıda satışı LeadTech-də`}</title> */}

        <meta property="og:locale" content="az_AZ" />
        <meta property="og:title" content={categoryName?.[currLang]} />
        <meta
          property="og:description"
          content={`${categoryName?.[currLang]} LEADTECH.AZ mağazasında satışı. Bakıda almaq - LEADTECH.AZ`}
        />
        <meta property="og:image" content={img} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:url" content={`https://leadtech.az`} />
        <meta property="og:type" content="product" />
      </Helmet>

      <div className="slc_ctg_container" style={{ display: "flex" }}>
        <div
          className={
            !isShowFilter ? "slc_ctg_filter" : "slc_ctg_filter open_filter"
          }
        >
          <div className="slc_ctg_box">
            <div className="slc_prod_name">
              {/*  */}
              <h3>{categoryName?.[currLang]?.toLocaleUpperCase("tr-TR")}</h3>
              <span>({filterResults?.count})</span>
              <div
                className="close_filter"
                onClick={() => setIsShowFilter(!isShowFilter)}
              >
                <LiaTimesSolid />
              </div>
            </div>

            <div className="slc_filter">
              <p>Filtr</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
              >
                <path
                  d="M7.6709 9.5C9.20709 9.5 10.4943 10.5658 10.8335 11.9983L20.1709 12C20.5851 12 20.9209 12.3358 20.9209 12.75C20.9209 13.1297 20.6387 13.4435 20.2727 13.4932L20.1709 13.5L10.8338 13.5007C10.4949 14.9337 9.20745 16 7.6709 16C6.13435 16 4.84684 14.9337 4.50802 13.5007L1.6709 13.5C1.25668 13.5 0.920898 13.1642 0.920898 12.75C0.920898 12.3703 1.20305 12.0565 1.56913 12.0068L1.6709 12L4.50802 11.9993C4.84684 10.5663 6.13435 9.5 7.6709 9.5ZM7.6709 11C6.90676 11 6.25701 11.4898 6.01843 12.1725L5.99786 12.2352L5.95937 12.3834C5.93416 12.5016 5.9209 12.6242 5.9209 12.75C5.9209 12.9048 5.94101 13.055 5.97875 13.1979L6.01856 13.3279L6.04425 13.3966C6.30145 14.0431 6.93281 14.5 7.6709 14.5C8.43466 14.5 9.08414 14.0107 9.32302 13.3285L9.36308 13.1978L9.346 13.2581C9.39471 13.0973 9.4209 12.9267 9.4209 12.75C9.4209 12.6452 9.41168 12.5425 9.39402 12.4428L9.36399 12.3057L9.34398 12.2353L9.29715 12.1024C9.03971 11.4565 8.40861 11 7.6709 11ZM14.1709 0C15.7075 0 16.995 1.06632 17.3338 2.49934L20.1709 2.5C20.5851 2.5 20.9209 2.83579 20.9209 3.25C20.9209 3.6297 20.6387 3.94349 20.2727 3.99315L20.1709 4L17.3338 4.00066C16.995 5.43368 15.7075 6.5 14.1709 6.5C12.6343 6.5 11.3468 5.43368 11.008 4.00066L1.6709 4C1.25668 4 0.920898 3.66421 0.920898 3.25C0.920898 2.8703 1.20305 2.55651 1.56913 2.50685L1.6709 2.5L11.0083 2.49833C11.3474 1.06582 12.6347 0 14.1709 0ZM14.1709 1.5C13.4068 1.5 12.757 1.98976 12.5184 2.6725L12.4979 2.73515L12.4594 2.88337C12.4342 3.0016 12.4209 3.12425 12.4209 3.25C12.4209 3.40483 12.441 3.55497 12.4788 3.69794L12.5186 3.82787L12.5443 3.89664C12.8014 4.54307 13.4328 5 14.1709 5C14.9347 5 15.5841 4.51073 15.823 3.82852L15.8631 3.69781L15.846 3.75808C15.8947 3.59729 15.9209 3.4267 15.9209 3.25C15.9209 3.14518 15.9117 3.04251 15.894 2.94275L15.864 2.80565L15.844 2.73529L15.7972 2.60236C15.5397 1.95647 14.9086 1.5 14.1709 1.5Z"
                  fill="#777777"
                />
              </svg>
            </div>

            <div
              style={{ justifyContent: "center" }}
              className="filter_price_btns"
            >
              <button
                style={{
                  display: !resetBtnstate ? "none" : "block",
                  height: "25px",
                }}
                onClick={resetAllValue}
              >
                {t("resetAll")}
              </button>
            </div>
            <>
              <div onClick={accorOpen} className="slc_price_context_name">
                <h4>{t("price")}</h4>

                {isActive ? <FiMinus size={16} /> : <FiPlus size={16} />}
              </div>

              <div
                className={`slc_price_context ${isActive ? "active" : ""}`}
                style={{ maxHeight: isActive ? "122px" : "0" }}
              >
                <div className="price_input">
                  {/* Min Price */}
                  <div className="price_field">
                    <input
                      type="number"
                      className="input_min"
                      value={minValue} // min price
                      placeholder={values[0]}
                      onChange={handleMinPriceChange}
                      required
                      // readOnly={false}
                    />
                    <span style={{ color: "#767676" }}>₼</span>
                  </div>
                  <div className="separator">
                    <FiMinus size={20} />
                  </div>
                  {/* Max Price */}
                  <div className="price_field">
                    <input
                      type="number"
                      className="input_max"
                      placeholder={values[1]}
                      value={maxValue} // max price
                      onChange={handleMaxPriceChange}
                      required

                      // readOnly={false}
                    />
                    <span style={{ color: "#767676" }}>₼</span>
                  </div>
                </div>
                <div className="filter_price_btns">
                  <button onClick={searchPriceBtn}>{t("search")}</button>
                </div>
              </div>

              {/* Show Category attributes */}
              {categoryAtributes.map((data, i) => (
                <ProdProducer
                  key={i}
                  data={data}
                  handleCheckboxChange={handleCheckboxChange}
                />
              ))}
            </>
            <button
              className="filter_btn"
              onClick={() => setIsShowFilter(!isShowFilter)}
            >
              {t("showResult")}
            </button>
          </div>
        </div>

        <div className="slc_respon_container">
          {/* Btn response style */}
          <div className="slc_filter_resp_container">
            <div
              className="slc_filter_responsive"
              onClick={() => setIsShowFilter(!isShowFilter)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
              >
                <path
                  d="M7.6709 9.5C9.20709 9.5 10.4943 10.5658 10.8335 11.9983L20.1709 12C20.5851 12 20.9209 12.3358 20.9209 12.75C20.9209 13.1297 20.6387 13.4435 20.2727 13.4932L20.1709 13.5L10.8338 13.5007C10.4949 14.9337 9.20745 16 7.6709 16C6.13435 16 4.84684 14.9337 4.50802 13.5007L1.6709 13.5C1.25668 13.5 0.920898 13.1642 0.920898 12.75C0.920898 12.3703 1.20305 12.0565 1.56913 12.0068L1.6709 12L4.50802 11.9993C4.84684 10.5663 6.13435 9.5 7.6709 9.5ZM7.6709 11C6.90676 11 6.25701 11.4898 6.01843 12.1725L5.99786 12.2352L5.95937 12.3834C5.93416 12.5016 5.9209 12.6242 5.9209 12.75C5.9209 12.9048 5.94101 13.055 5.97875 13.1979L6.01856 13.3279L6.04425 13.3966C6.30145 14.0431 6.93281 14.5 7.6709 14.5C8.43466 14.5 9.08414 14.0107 9.32302 13.3285L9.36308 13.1978L9.346 13.2581C9.39471 13.0973 9.4209 12.9267 9.4209 12.75C9.4209 12.6452 9.41168 12.5425 9.39402 12.4428L9.36399 12.3057L9.34398 12.2353L9.29715 12.1024C9.03971 11.4565 8.40861 11 7.6709 11ZM14.1709 0C15.7075 0 16.995 1.06632 17.3338 2.49934L20.1709 2.5C20.5851 2.5 20.9209 2.83579 20.9209 3.25C20.9209 3.6297 20.6387 3.94349 20.2727 3.99315L20.1709 4L17.3338 4.00066C16.995 5.43368 15.7075 6.5 14.1709 6.5C12.6343 6.5 11.3468 5.43368 11.008 4.00066L1.6709 4C1.25668 4 0.920898 3.66421 0.920898 3.25C0.920898 2.8703 1.20305 2.55651 1.56913 2.50685L1.6709 2.5L11.0083 2.49833C11.3474 1.06582 12.6347 0 14.1709 0ZM14.1709 1.5C13.4068 1.5 12.757 1.98976 12.5184 2.6725L12.4979 2.73515L12.4594 2.88337C12.4342 3.0016 12.4209 3.12425 12.4209 3.25C12.4209 3.40483 12.441 3.55497 12.4788 3.69794L12.5186 3.82787L12.5443 3.89664C12.8014 4.54307 13.4328 5 14.1709 5C14.9347 5 15.5841 4.51073 15.823 3.82852L15.8631 3.69781L15.846 3.75808C15.8947 3.59729 15.9209 3.4267 15.9209 3.25C15.9209 3.14518 15.9117 3.04251 15.894 2.94275L15.864 2.80565L15.844 2.73529L15.7972 2.60236C15.5397 1.95647 14.9086 1.5 14.1709 1.5Z"
                  fill="#777777"
                />
              </svg>
              <p>Filtr</p>
            </div>
          </div>

          {/* Components for show product list by 12 peaces */}
          {loaderTwo ? (
            <Loading />
          ) : (
            <>
              <div className="sort-buttons">
                <button
                  style={{ border: sortOrder ? "1px solid #000" : "" }}
                  onClick={sortAscending}
                >
                  {t("ascPrice")}
                </button>
                <button
                  style={{
                    border: sortOrder === false ? "1px solid #000" : "",
                  }}
                  onClick={sortDescending}
                >
                  {t("descPrice")}
                </button>
              </div>

              <>
                <div className="diff_prod_box">
                  {btnTrue
                    ? sortedArr?.map((data, i) => (
                        <ProductSelectedCategory key={i} data={data} />
                      ))
                    : filterResults?.results?.map((data, i) => (
                        <ProductSelectedCategory key={i} data={data} />
                      ))}
                </div>

                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={(page) => handlePageClick(page)}
                  pageLinkClassName="my_link"
                  activeItemClassName="my_active"
                />
              </>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectedCategory;
