import { initialState } from "./initialState";
import { createSlice } from "@reduxjs/toolkit";

export const MainSlice = createSlice({
  name: "MAIN_SLICE",
  initialState: initialState,

  reducers: {
    isLoaderFuncStart: (state) => {
      state.loaderTwo = true;
    },
    resetCatAtri: (state) => {
      state.categoryAtributes = [];
    },

    resetProdID: (state) => {
      state.productIDList = [];
    },

    isLoaderFuncEnd: (state) => {
      state.loaderTwo = false;
    },

    categoryListFunc: (state, action) => {
      state.categorytList = action.payload;

      // Удалил из за компонента SelectedCategory
      state.loaderCatSub = false;
    },
    productListFunc: (state, action) => {
      state.productList = action.payload;
      state.loader = false;
      state.loaderBtns = false;
      state.loaderAllBtns = false;
    },
    categoryClickListFunc: (state, action) => {
      state.categoryClickList = action.payload;
    },

    // Спмсок по ID для категории, например только ноуты
    categoryAtributesFunc: (state, action) => {
      state.categoryAtributes = action.payload;
    },

    // Список товаров по категориям
    producstListFunc: (state, action) => {
      state.producstList = action.payload;
      state.loaderBtns = false;
    },

    // Страница единичного товара
    productIDListFunc: (state, action) => {
      state.productIDList = action.payload;
      state.loaderTwo = false; //Добавил Эмин для сингл страницы
    },
    loaderTwoFunc: (state, action) => {
      state.filterResults = [];

      state.loaderTwo = true; //Добавил Эмин для сингл страницы
    },
    allProductsBtn: (state, action) => {
      state.loaderAllBtns = true;
      state.allProductsStatus = true;

      // state.productsStatus = false;
    },
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload;
    },
    setProductSid: (state, action) => {
      state.productSid = action.payload;
    },
    // Удалит этот код и посмотреть работает или нет
    loaderBtnsFunc: (state, action) => {
      state.loaderBtns = false;
    },
    handleClick: (state, action) => {
      state.activeIndex = action.payload;
      state.productSid = action.payload;
      state.allProductsStatus = false;
      state.productsStatus = true;
      state.loaderBtns = true;
    },
    carouselImagesFunc: (state, action) => {
      state.carouselImages = action.payload;
    },
    cardsImageFunc: (state, action) => {
      state.cardsImage = action.payload;
    },
    forVideoRekFunc: (state, action) => {
      state.forVideoRek = action.payload;
    },

    // Для пагинации. Отображения товаров по 10 шт
    proList10Func: (state, action) => {
      state.getProList10Value = action.payload;
      state.loaderBtns = true;
      state.loaderTwo = false;
    },

    // Для списка товара при выборе фильтера
    filterProductsFunc: (state, action) => {
      state.filterResults = action.payload;
      state.categoryName = action.payload.category;

      state.loaderTwo = false;
    },
    searchResultFunc: (state, action) => {
      state.searchResult = action.payload;
    },

    // Для списка котнактов
    contactInfoFunc: (state, action) => {
      state.contactInfo = action.payload;
    },

    // Для партеров
    partnersImgFunc: (state, action) => {
      state.partnersImg = action.payload;
    },

    // для кредиток
    instCardsfunc: (state, action) => {
      state.instCards = action.payload;
    },
  },
});

export const Data = MainSlice.reducer;
export const {
  categoryListFunc,
  productListFunc,
  categoryClickListFunc,
  categoryAtributesFunc,
  producstListFunc,
  productIDListFunc,
  allProductsBtn,
  setActiveIndex,
  setProductSid,
  handleClick,
  carouselImagesFunc,
  cardsImageFunc,
  loaderBtnsFunc,
  loaderTwoFunc,
  forVideoRekFunc,
  proList10Func,
  filterProductsFunc,
  searchResultFunc,
  contactInfoFunc,
  partnersImgFunc,
  instCardsfunc,
  isLoaderFuncStart,
  isLoaderFuncEnd,
  resetCatAtri,
  resetProdID,
} = MainSlice.actions;
