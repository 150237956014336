import axios from "axios";

import {
  categoryAtributesFunc,
  categoryListFunc,
  producstListFunc,
  productIDListFunc,
  productListFunc,
  carouselImagesFunc,
  cardsImageFunc,
  forVideoRekFunc,
  proList10Func,
  filterProductsFunc,
  searchResultFunc,
  contactInfoFunc,
  partnersImgFunc,
  instCardsfunc,
  resetProdID,
} from "../redux/MainReducer";

const staUrl = "https://api.leadtech.az/api";
// const staUrl = "https://techlead.pythonanywhere.com/api";

// Для вызова списка категорий
export const getCategoryList = () => async (dispatch) => {
  return await axios
    .get(staUrl + "/categories")
    .then((resp) => {
      dispatch(categoryListFunc(resp.data));
    })
    .catch((err) => {});
};

// Для вызова всех списков товара
export const getProductList = () => async (dispatch) => {
  try {
    const resp = await axios.get(staUrl + "/product-list/");

    dispatch(productListFunc(resp.data));
  } catch (err) {}
};

// Для вызова списков товара по 10шт. Пагинация
export const getProList10 = (queryParams) => async (dispatch) => {
  try {
    const apiUrl = queryParams || `${staUrl}/products-list/`; // Если queryParams существует, используем его, иначе - дефолтный URL.
    const response = await axios.get(apiUrl);

    if (response?.data) {
      dispatch(proList10Func(response.data));
    }
  } catch (error) {
    console.error("Error sending request to the server:", error);
  }
};

// Для вызова списков атрибутов Категорий
export const getCategoryAtributes = (id) => async (dispatch) => {
  if (id) {
    try {
      const response = await axios.get(`${staUrl}/category-attributes/${id}/`);
      dispatch(categoryAtributesFunc(response.data));
    } catch (error) {}
  } else {
  }
};

// Список товаров согласно id конечных категорий, например категория Ноутбики = 11, то показывает все товары добавленные в ноутбуки
// Используется в компоненте Products.jsx
export const getProductsList = (id, pageNum) => async (dispatch) => {
  if (id) {
    try {
      const url =
        id && !pageNum
          ? `https://api.leadtech.az/api/products-list/?category=${id}`
          : `https://api.leadtech.az/api/products-list/?category=${id}&page=${pageNum}`;

      const resp = await axios.get(url);

      // const response = await axios.get(`${staUrl}/all-products/${id}/`);

      dispatch(producstListFunc(resp.data));
    } catch (error) {
      // Обработка ошибок
    }
  } else {
    // Обработка случая, когда id не определен
  }
};

// Список товаров при фильтрации на странице отображения товаров по id категорий
export const getFilteredProducts = (queryParams) => async (dispatch) => {
  // console.log(queryParams);
  if (queryParams) {
    try {
      const apiUrl = `${queryParams}`;
      const response = await axios.get(apiUrl);
      if (response && response?.data) {
        dispatch(filterProductsFunc(response.data));
      }
      // console.log(response.data);
    } catch (error) {
      console.error("Error sending request to the server:", error);
    }
  }
};

// Список ТОВАРА единичного продукта, для отображения на single Page
export const getProductIDList = (id) => async (dispatch) => {
  dispatch(resetProdID());

  return await axios
    .get(`${staUrl}/product/${id}/`)
    .then((resp) => {
      dispatch(productIDListFunc(resp.data));
    })
    .catch((err) => {});
};

// Карусел
export const getCarouselImages = () => async (dispatch) => {
  return await axios
    .get(staUrl + "/carousel/")
    .then((resp) => {
      dispatch(carouselImagesFunc(resp.data));
    })
    .catch((err) => {});
};

// Карточки для рекламы
export const getCardsImage = () => async (dispatch) => {
  return await axios
    .get(staUrl + "/cards/")
    .then((resp) => {
      dispatch(cardsImageFunc(resp.data));
    })
    .catch((err) => {});
};

// Для видео рекламы
export const getForVideoRek = () => async (dispatch) => {
  return await axios
    .get(staUrl + "/videos/")
    .then((resp) => {
      dispatch(forVideoRekFunc(resp.data));
    })
    .catch((err) => {});
};

// Для результатов поиска
export const getSearchResult = () => async (dispatch) => {
  return await axios
    .get(staUrl + "/search/")
    .then((resp) => {
      dispatch(searchResultFunc(resp.data));
    })
    .catch((err) => {});
};

// contact info

export const getContactInfo = () => async (dispatch) => {
  return await axios
    .get(staUrl + "/contact-info/")
    .then((resp) => {
      dispatch(contactInfoFunc(resp.data));
    })
    .catch((err) => {});
};

// для изо партнеров
export const getPartnersImage = () => async (dispatch) => {
  return await axios
    .get(staUrl + "/collaborators/")
    .then((resp) => {
      dispatch(partnersImgFunc(resp.data));
    })
    .catch((err) => {});
};

// для слайда оплаты по кредитке

export const getInstCards = () => async (dispatch) => {
  return await axios
    .get(staUrl + "/installmentscards/")
    .then((resp) => {
      dispatch(instCardsfunc(resp.data));
    })
    .catch((err) => {});
};
